import React from "react";
import InfoContactForm from "../../components/info-contact-us-form";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";

const subscriberLpTakeControlOfYourContactCenter =  process.env.GATSBY_SUBSCRIBER_LP_TAKE_CONTROL_OF_YOUR_CONTACT_CENTER_FORM_TAG;

const Main = ({location}) => (
  <section className="container-xl p-0">
    <div className="row m-auto">
      <div className="col-12 col-md-6 py-5 pr-md-5 py-md-6">
        <h3>729 Solutions Is Now a Twilio Flex Partner</h3>
        <h1>Take Complete Control of Your Contact Center</h1>
        <p>
          Move your contact center to the world’s only fully programmable
          platform in the cloud. The Twilio Flex infrastructure powers over half
          a million agents. It's time for you to escape the limitations of SaaS.
        </p>
        <ul>
          <li>
            <i>Programmability</i> - The Twilio
            Flex application platform approach allows you to change any feature
            at any layer of the stack.
          </li>
          <li>
            <i>Omni-channel</i> - Reduce costs and
            stay connected with your customers across a wide breadth of digital
            channels.
          </li>
          <li>
            <i>Scale</i> - Grow your contact
            center and deploy globally in an instant.
          </li>
        </ul>
        <p>
          <strong>
            729 Solutions is proud to announce our latest partnership with
            Twilio Flex!
          </strong>
        </p>
        <p>
          Contact 729 Solutions and discover how easy it is to add remote
          agents, new channels, chatbots and interactive voice response to your
          contact center in the cloud.
        </p>
      </div>
      <div className="col-12 col-md-6 bg-primary py-6 px-lg-6">
        <h3 className="text-center text-white">
          Contact Us for a FREE Consultation
        </h3>
        <div className="bg-white p-4 mt-3">
          <InfoContactForm
            location={location}
            tags={[subscriberLpTakeControlOfYourContactCenter]}
            buttonText="SUMBIT"
            onSubmitSuccess={()=> navigate('/lp/take-control-of-your-contact-center-ty')}/>
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 align-self-center">
          <h2>What our clients are saying about us</h2>
        </div>
        <div className="col-12 col-md-6">
          <Quote
            quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed. They are personable, caring, and reliable. I would recommend working with them, and feel confident everyone would feel the same value we did."
            name="KAYLA FISCHER"
            company="HireVue, Inc"
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Main location={location}/>
    <Testimonial />
  </Layout>
);

export default Index;
